<template>
  <div>
    <videos-list lang="RU"></videos-list>
  </div>
</template>

<script>
import VideosList from './VideosList.vue'

/*eslint-disable */
export default {
  components: {
    VideosList,
  },
  data() {
    return {}
  },
}
</script>
